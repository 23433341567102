import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "",
		component: () => import("../views/main/index.vue"),
		hidden: true,
	},
	{
		path: "/carousel",
		component: () => import("../views/main/carousel.vue"),
		hidden: true,
	},
	// {
	// 	path: "/pages",
	// 	component: () => import("../views/clasiffier/help.vue"),
	// 	hidden: true,
	// 	children: [
	// 		{
	// 			path: "trailers",
	// 			component: () => import("../views/pages/trailers/index.vue"),
	// 			hidden: true,
	// 		},
	// 		{
	// 			path: "instruction",
	// 			component: () =>
	// 				import("../views/clasiffier/components/instuction.vue"),
	// 		},
	// 		{
	// 			path: "chavo",
	// 			component: () => import("../views/clasiffier/components/chavo.vue"),
	// 		},
	// 	],
	// },
	{
		path: "/pages/trailers",
		component: () => import("../views/pages/trailers/index.vue"),
		hidden: false,
	},
	{
		path: "/movie/details/:id",
		component: () => import("../views/main/components/movie/details.vue"),
		hidden: false,
	},
	{
		path: "/movie/actor/:id",
		component: () => import("../views/main/components/movie/actor.vue"),
		hidden: false,
	}
];

const router = new VueRouter({
	base: process.env.BASE_URL,
	//mode:'history',
	routes,
});

export default router;
